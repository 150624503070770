











































import themeConfig from '@themeConfig';
import type { SuperAdminOperatorMenuItem } from '@/api/schema';
import type { PropType } from 'vue';

export default {
  name: 'SuperAdminOperatorMenuGroup',
  props: {
    item: {
      type: Object as PropType<SuperAdminOperatorMenuItem>,
      default: (): SuperAdminOperatorMenuItem => {
        return {} as SuperAdminOperatorMenuItem;
      },
      required: true
    },
    isLastItem: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  computed: {
    subMenuItems(): SuperAdminOperatorMenuItem[] {
      return this.item.children.filter(
        (child: SuperAdminOperatorMenuItem) => !child?.hide
      );
    },
    alternateIcon(): string {
      return themeConfig.menu.groupChildIcon;
    }
  }
};
